import { enerbitColorsV2 } from "@enerbit/base";
import CheckCircleOutlinedIcon from "@mui/icons-material/CheckCircleOutlined";
import { Avatar, Box, Chip, Typography } from "@mui/material";
import classNames from "classnames";
import moment from "moment";
import { useCallback, useEffect, useState } from "react";
import { Pqr } from "../../../models";
import { useHelpDeskStore } from "../../../store/pqrs.store";
import { getColorChipRemainingDays, getImageType } from "../../../utils";
import { getLabelRemainingDays } from "../../../utils/date.utils";
import "./card-pqr.scss";

export type CardPqrProps = {
    isSelected?: boolean;
    pqr: Pqr;
};

export const CardPqr = ({ isSelected = true, pqr }: CardPqrProps) => {
    const [remainingDays, setRemainingDays] = useState<number | null>(null);
    const [remainingDaysLabel, setRemainingDaysLabel] = useState<string>("");
    const { onSetPqrSelected } = useHelpDeskStore();

    const _getLabelRemainingDays = useCallback(() => {
        const { remainingDays, label } = getLabelRemainingDays(
            moment(pqr.pqr_creation_date),
            15,
        );
        setRemainingDays(remainingDays);
        setRemainingDaysLabel(label);
    }, [pqr.pqr_creation_date]);

    useEffect(() => {
        _getLabelRemainingDays();
    }, [_getLabelRemainingDays]);

    const _getColorText = useCallback(() => {
        return isSelected
            ? enerbitColorsV2.primary.brand[800]
            : enerbitColorsV2.primary.gray[800];
    }, [isSelected]);

    const _onCardPqrClicked = (): void => {
        onSetPqrSelected(pqr);
    };

    return (
        <Box
            className={classNames("card-pqr", {
                "card-pqr-selected": isSelected,
            })}
            onClick={_onCardPqrClicked}
        >
            <Box className="card-pqr-title" width="100%">
                <Box width="80%">
                    <Typography
                        variant="body1"
                        fontWeight="bold"
                        color={_getColorText()}
                    >
                        {pqr.name}
                    </Typography>
                </Box>
                <Typography
                    variant="caption"
                    fontSize="10px"
                    textAlign="right"
                    color={enerbitColorsV2.primary.gray[400]}
                >
                    {moment(pqr.pqr_creation_date).format("DD MMM")}
                </Typography>
            </Box>
            <Box>
                <Box display="flex" justifyContent="space-between">
                    <Chip
                        label={pqr.type}
                        variant="outlined"
                        sx={{
                            backgroundColor:
                                enerbitColorsV2.primary.warning[50],
                            color: enerbitColorsV2.primary.warning[600],
                        }}
                    />
                    <Box display="flex" gap="4px" alignItems="center">
                        <Chip
                            label={remainingDaysLabel}
                            variant="filled"
                            icon={<CheckCircleOutlinedIcon />}
                            sx={{
                                backgroundColor:
                                    getColorChipRemainingDays(remainingDays),
                                color: "white",
                                "& .MuiChip-icon": {
                                    color: "white",
                                    width: "14px",
                                    height: "14px",
                                },
                            }}
                        />
                        <Avatar
                            sx={{ width: "22px", height: "22px" }}
                            src={`data:${getImageType(pqr.x_author_avatar)};base64,${pqr.x_author_avatar}`}
                        />
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};
