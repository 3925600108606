import { enerbitColorsV2 } from "@enerbit/base";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import { Avatar, Box, Typography } from "@mui/material";
import DOMPurify from "dompurify";
import { useEffect, useState } from "react";
import Config from "../../../../config/config";
import { PqrMessage } from "../../../../models";
import { getImageType } from "../../../../utils";
import "./styles/card-comment.scss";

export type CardCommentProps = {
    message: PqrMessage;
};

export const CardComment = ({
    message: { body, author_avatar, tracking_values, date },
    title,
}: CardCommentProps & { title: string }) => {
    const [descriptionHTML, setDescriptionHTML] = useState<string>(body);
    useEffect(() => {
        if (body === "") return;
        const sanitizedDescription = DOMPurify.sanitize(body);
        setDescriptionHTML(sanitizedDescription);
    }, [body]);

    const imageType = author_avatar ? getImageType(author_avatar) : "";

    const _getDescription = () => {
        if (descriptionHTML === "" && tracking_values.length > 0) {
            const filteredTrackingValues = tracking_values.filter(
                (tv) => tv.change_date <= date,
            );
            if (filteredTrackingValues.length === 0)
                return (
                    <Typography variant="body2" fontSize="14px">
                        Ticket creado
                    </Typography>
                );
            return (
                <Box gap="4px" display="flex" flexDirection="column">
                    <Typography variant="body2" fontSize="14px">
                        {filteredTrackingValues[0].display_name}
                    </Typography>

                    <ul>
                        <li>
                            <Box display="flex" gap="4px" alignItems="center">
                                <Typography variant="body2" fontSize="12px">
                                    {filteredTrackingValues[0].old_value}
                                </Typography>
                                <ArrowForwardIcon
                                    sx={{
                                        width: "16px",
                                        height: "16px",
                                    }}
                                />
                                <Typography variant="body2" fontSize="12px">
                                    {filteredTrackingValues[0].new_value}
                                </Typography>
                            </Box>
                        </li>
                    </ul>
                </Box>
            );
        }
        return <div dangerouslySetInnerHTML={{ __html: descriptionHTML }} />;
    };

    return (
        <Box className="card-comment-container">
            <Box display="flex" gap="14px" alignItems="center">
                {author_avatar && (
                    <Avatar
                        sx={{
                            height: "34px",
                            width: "34px",
                        }}
                        src={`data:${imageType};base64,${author_avatar}`}
                    />
                )}
                <Box>
                    <Typography
                        variant="body2"
                        fontWeight="bold"
                        color={enerbitColorsV2.primary.gray[800]}
                    >
                        {title}
                    </Typography>
                </Box>
            </Box>
            {_getDescription()}
        </Box>
    );
};

export type CardDescriptionProps = {
    description: string;
};
export const CardDescription = ({ description }: CardDescriptionProps) => {
    const [descriptionHTML, setDescriptionHTML] = useState<string>(description);

    useEffect(() => {
        const sanitizedDescription = DOMPurify.sanitize(description);
        const modifiedDescription = modifyImageSrc(sanitizedDescription);
        setDescriptionHTML(modifiedDescription);
    }, [description]);

    const modifyImageSrc = (html: string): string => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, "text/html");
        const images = doc.querySelectorAll("img");

        images.forEach((img) => {
            const originalSrc = img.getAttribute("src");
            if (originalSrc) {
                const odooURL = Config.getInstance().odooURL;
                if (odooURL) {
                    const modifiedSrc = `${odooURL}${originalSrc}`;
                    img.setAttribute("src", modifiedSrc);
                }
            }
        });

        return doc.body.innerHTML;
    };

    return (
        <Box className="card-comment-container" marginBottom="12px">
            <Typography
                variant="body2"
                fontWeight="bold"
                color={enerbitColorsV2.primary.gray[800]}
            >
                Descripción
            </Typography>
            <div dangerouslySetInnerHTML={{ __html: descriptionHTML }} />
        </Box>
    );
};
